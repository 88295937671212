export default {
    listEnum: [
        {
            text: 'Ativo',
            value: 1,
        },
        {
            text: 'Pendente Validação',
            value: 2,
        },
        {
            text: 'Pendente Informação',
            value: 3,
        },
        {
            text: 'Rejeitado',
            value: 4,
        },
    ],

    listStatusEnum: [
        {
            text: 'Ativo',
            value: true,
        },
        {
            text: 'Inativo/Análise',
            value: false,
        },
    ]
  }
    